import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

import ContactForm from '../components/contact/contactForm'

import '../components/contact/contact.css'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <h1>
      Contact Us
    </h1>
    <div className="contact-form-outer-container">
      <ContactForm />
    </div>
  </Layout>
)

export default ContactPage
